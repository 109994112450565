import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  wrap: {
    position: 'absolute',
    paddingTop: '2rem',
    left: '50%',
    transform: 'translate(-50%, 0)',
    zIndex: 1,
    display: 'flex',
    height: '3rem',
    justifyContent: 'center',
    alignItems: 'center',
    '& button:not(:last-child)': {
      marginRight: theme.spacing(2),
    },
  },
  button: {
    height: '3rem',
  },
}));

export interface ITocPanel {
  onAddArticle():void;
  onAddHeader():void;
}

const Panel: FC<ITocPanel> = ({ onAddArticle, onAddHeader }) => {
  const classes = useStyles();
  return (
    <div className={classes.wrap}>
      <Button
        variant="contained"
        color="primary"
        className={classes.button}
        onClick={onAddArticle}
        data-seleniumid="add-article"
      >
        Add Article by DOI
      </Button>
      <Button
        variant="contained"
        color="primary"
        className={classes.button}
        onClick={onAddHeader}
        data-seleniumid="add-header"
      >
        Add Header
      </Button>
    </div>
  );
};

export default Panel;
