type ErrorByCode = {
  [key: string]: (code?:number) => string;
}

type ErrorByStatus = {
  [key: number]: string;
}

export const errorsByCode: ErrorByCode = {
  FAILED_AUTHENTICATE_USER: (code?: number) => {
    switch (code) {
      case 401:
        return 'Invalid email or password. Please try again or contact your Wiley editorial colleague or the System Administrator.';
      case 403:
        return 'Unauthorized activity. Please contact the System Administrator.';
      case 423:
        return 'The account is locked for 15 minutes. Please wait or contact the System Administrator.';
      default:
        return 'The user cannot be authenticated at this time. Please try again or contact the System Administrator.';
    }
  },
  FAILED_CREATE_ISSUE: (code?: number) => {
    if (code === 409) return 'The DOI entered is already in use. Please provide a different DOI.';
    return 'The issue has not been created. Please re-save now or try later.';
  },
  FAILED_COMPLETE_ISSUE: () => 'The issue cannot be saved. Please re-save now or try later.',
  FAILED_SAVE_COVER: () => 'The issue cover has not been saved. Please re-save now or try later.',
  FAILED_SAVE_TOC: () => 'The issue Table of contents has not been saved. Please re-save now or try later.',
  FAILED_GET_ISSUE: () => 'The issue cannot be saved. Please re-save now or try later.',
  FAILED_UPDATE_ISSUE: () => 'The edits to the issue have not been saved. Please re-save now or try later.',
  FAILED_PUBLISH_ISSUE: () => 'The issue failed to publish. Please try to \'Publish\' again or contact the System Administrator.',
  FAILED_SEARCH_USER: () => 'The search cannot be performed at this time. Please try again or contact the System Administrator.',
  FAILED_GET_SOCIETIES: () => 'Society list is temporary unavailable. Please try again later or contact the System Administrator.',
  FAILED_SAVE_USER: () => 'The user has not been saved. Please re-save now or try later.',
};

export const commonErrorsByStatus: ErrorByStatus = {
  401: 'Your session has expired.',
  404: 'Object not found. Please contact the System Administrator.',
};
