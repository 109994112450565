import apiCall, { uploadFile } from 'src/app/services/api';
import { generatePath } from 'react-router';
import { REQUEST_PREFIX } from 'src/app/services/http';
import { Article, VirtualIssue } from 'src/mock/db';
import endpoints from './endpoints';

export const authenticate = (data: any) => apiCall(endpoints.authenticate, data, { method: 'POST' });
export const logout = () => apiCall(endpoints.logout, {}, { method: 'POST' });

export const fetchJournals = () => apiCall(endpoints.journals);
export const fetchFeatureFlags = () => apiCall(endpoints.features);
export const fetchRecentJournals = (params: any = {}) => apiCall(endpoints.recentJournals, params);
export const postRecentJournal = (id: string) => apiCall(endpoints.recentJournals, { id }, { method: 'POST' });
export const fetchVirtualIssues = (params: any = {}) => apiCall(endpoints.virtualIssues, params, { isList: true });
export const fetchDetailedIssues = (id: string) => apiCall<VirtualIssue>(endpoints.virtualIssue, { id }, { ignoreParams: true });
export const fetchUsers = ({ email, offset, size }: any = {}) => apiCall(`${endpoints.users}?offset=${offset}&size=${size}`, email ? { email } : undefined, { isList: true });
export const postUser = (params: any) => apiCall(endpoints.users, params, { method: 'POST' });
export const patchUser = (params: any) => apiCall(endpoints.user, params, { method: 'PATCH' });
export const fetchUser = (id: string) => apiCall(endpoints.user, { id }, { ignoreParams: true });
export const fetchSocieties = () => apiCall(endpoints.societies);
export const validateUniqueDoi = (doi: string) => apiCall<any>(`${endpoints.virtualIssues}?doi=${doi}`, {}, { method: 'HEAD', customPath: true, ignoreEmptyBody: true });
export const fetchTOC = (id: string) => apiCall<string>(endpoints.TOC, { id }, { contentType: 'text/html', ignoreParams: true });

export const createVirtualIssue = (virtualIssueFormData: any) =>
  apiCall<VirtualIssue>(endpoints.virtualIssues, virtualIssueFormData, { method: 'POST' });
export const updateVirtualIssue = (id: string, virtualIssueFormData: any) =>
  apiCall<VirtualIssue>(endpoints.virtualIssue, { id, ...virtualIssueFormData }, { method: 'PATCH' });
export const publishVirtualIssue = (id: string) => apiCall(endpoints.virtualIssuesPublish, { id }, { method: 'POST', ignoreEmptyBody: true });

export const getArticles = (params: any) => apiCall<Article[]>(endpoints.articles, params, { method: 'POST', shouldTrimData: false });

export const uploadCoverImage = (file: any) => uploadFile(endpoints.images, file);
export const getImage = (id: string) => `${REQUEST_PREFIX}${generatePath(endpoints.image, { id })}`;
export const validateDOI = (doi: string) => apiCall(`${endpoints.validateDOI}${doi}`,
  { method: 'HEAD', crossDomain: true },
  { customPath: true });
