import React, { memo, useRef, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import debounce from 'p-debounce';
import { InputAdornment, TextField } from '@material-ui/core';
import { REGEX_VALIDATION_VI_DOI, DOI_VALIDATION_DELAY } from 'src/app/constants';
import { logout } from 'src/app/pages/Login/redux/AuthDucks';
import { validateUniqueDoi } from 'src/app/redux/api';
import { FieldId, FieldMeta } from '../interfaces';
import { showToastr, VIFormMessageTypes } from '../../../common/utils';
import DOIIcon from './components/DOIIcon';

interface IDOIField {
  value?: string;
  initialValue?: string;
  journalDoi?: string;
  error?: boolean;
  disabled?: boolean;
  valueLimit?: number;
  updateMeta: (field: string, fieldMeta: FieldMeta) => void;
  meta?: FieldMeta;
  onChange: (field: FieldId, value: string) => void;
}

const DOIField = memo(({
  error,
  value = '',
  initialValue = '',
  journalDoi = '',
  onChange,
  disabled,
  updateMeta,
  meta = {},
}: IDOIField) => {
  const dispatch = useDispatch();
  const inputRef = useRef<HTMLInputElement>(null);
  const validate = useCallback(async (currentValue: string) => {
    const isValid = REGEX_VALIDATION_VI_DOI.test(currentValue);

    if (initialValue === currentValue) {
      updateMeta('doi', { validated: true });
      return;
    }

    if (!isValid) {
      updateMeta('doi', { validated: false, error: true });
      return showToastr(VIFormMessageTypes.patternDoiInvalid);
    }

    let validated = false;
    let error = true;
    let toasterTypeNumber;

    updateMeta('doi', { validated, validating: true });

    try {
      const [call] = await validateUniqueDoi(currentValue);
      await call;
      toasterTypeNumber = VIFormMessageTypes.doiExists;
    }
    catch (err) {
      const { status } = err?.response;

      switch (status) {
        case 401:
          dispatch(logout());
          return;
        case 403:
          validated = false;
          toasterTypeNumber = VIFormMessageTypes.forbidden;
          break;
        case 404:
          validated = true;
          error = false;
          break;
        case 503:
          validated = false;
          toasterTypeNumber = VIFormMessageTypes.serviceUnavailable;
          break;
        default:
          validated = false;
          break;
      }
    }
    finally {
      if (error) showToastr(toasterTypeNumber);
      updateMeta('doi', { validating: false, validated, error });
    }
  }, [value, inputRef, updateMeta]);

  const validateDebounce = useCallback(debounce(validate, DOI_VALIDATION_DELAY), []);

  const change = useCallback((e) => {
    const rawValue = (e.target.value || '').trim();
    updateMeta('doi', { validated: false, error: false });
    const journalDoiPath = `${journalDoi}.`;
    const value = rawValue.startsWith(journalDoiPath) ? rawValue : journalDoiPath;
    onChange('doi', value);
    validateDebounce(value);
  }, [onChange, updateMeta, journalDoi]);

  return (
    <TextField
      inputRef={inputRef}
      error={error}
      value={value}
      disabled={disabled}
      helperText="e.g. 10.1002/(ISSN)1234-5678.gold-from-mercury"
      onChange={change}
      variant="outlined"
      required
      id="doi"
      label="VI DOI"
      InputProps={{
        inputProps: {
          'data-seleniumid': 'doi-input',
          maxLength: 256,
        },
        endAdornment: (
          <InputAdornment position="end">
            <DOIIcon meta={meta} />
          </InputAdornment>
        ),
      }}
    />
  );
});

export default DOIField;
